import { environment } from "environment";
import TimeChartDataProvider from "wes_shell_app/time-chart-data-provider";

class ChartsCommonDataProvider extends TimeChartDataProvider {
  protected get serviceApiUrl() {
    return environment.serviceApi;
  }
  protected get endpointUrl() {
    return `ui/graph/${this.stationId}`;
  }
}

export class ChartsDataProvider {
  readonly finishedTransports = new ChartsCommonDataProvider([
    { name: "Total Transports Finished" },
  ]);
  readonly finishedByRobot = new ChartsCommonDataProvider([
    { name: "Transports Finished by Robot", tagName: "userName" },
  ]);
}
